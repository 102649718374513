<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <!-- Search -->
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="query.withDrawType"
              :options="codes.withDrawType"
              placeholder="탈퇴 사유"
              :reduce="option => option.code"
              class="el-def"
              style="width: 250px"
            />
            <b-form-input
              v-model="query.searchText"
              placeholder="검색어를 입력하세요"
              class="el-def"
              maxlength="30"
              @keyup.enter="refetchData()"
            />
            <b-form-datepicker
              id="datepicker-placeholder1"
              v-model="query.startDate"
              class="el-def"
              placeholder="탈퇴일(From)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              id="datepicker-placeholder2"
              v-model="query.endDate"
              class="el-def"
              placeholder="탈퇴일(To)"
            />
          </b-col>
          <b-col
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search mr-50"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              고객 수 [{{ totalRecords }}] 명
            </h5>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refWithdrawTable"
        primary-key="Id"
        :items="fetchWithdraw"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        selectable
        sticky-header
        select-mode="single"
        class="position-relative"
      >
        <template #cell(withdrawType)="data">
          {{ data.item.withdrawTypeText }}
        </template>
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD hh:mm') }}
          </span>
        </template>
      </b-table>
      <b-pagination
        v-model="currPage"
        :total-rows="totalRecords"
        limit="10"
        per-page="10"
        align="center"
      />
    </b-card>
  </div>
</template>

<script>
import axios from "@axios"
import vSelect from 'vue-select'
import {
  ref, onMounted, watch,
} from '@vue/composition-api'
import { useToast } from "vue-toastification/composition"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import dayjs from 'dayjs'

export default {
  components: {
    vSelect,
  },
  setup() {
    onMounted(() => {
      fetchCodes()
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon

      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    // Query Data
    const query = ref({
      withDrawType: null,
      searchText: null,
      startDate: dayjs().add(-1, 'month').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
    })

    const refWithdrawTable = ref(null)
    const sortBy = ref("Id")
    const currPage = ref(1)
    const isSortDesc = ref(true)
    const totalRecords = ref(0)
    const tableColumns = [
      {
        key: "id", label: "ID", sortable: true, thStyle: { width: '7%' },
      },
      {
        key: "withdrawType", label: "탈퇴사유", sortable: true, thStyle: { width: '20%' },
      },
      { key: "etcText", label: "기타입력", sortable: true },
      {
        key: "createDate", label: "탈퇴일시", sortable: true, thStyle: { width: '12%' },
      },
    ]

    const codes = ref({
      withDrawType: [],
    })

    watch([currPage], () => {
      refetchData()
    })

    const fetchCodes = () => {
      axios.get('/fa/withdraw/codes')
        .then(rs => {
          const {
            withdrawType,
          } = rs.data

          codes.value.withDrawType = withdrawType
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }

    const fetchWithdraw = (ctx, callback) => {
      axios.post('/fa/withdraw/list', {
        search:
        {
          withDrawType: query.value.withDrawType,
          searchText: query.value.searchText,
          startDate: query.value.startDate,
          endDate: query.value.endDate,
        },
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
        pagination: {
          number: currPage.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data

          callback(items)

          totalRecords.value = totalRecord
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const refetchData = () => {
      refWithdrawTable.value.refresh()
    }

    return {
      codes,
      pushToast,
      fetchCodes,
      sortBy,
      isSortDesc,
      tableColumns,
      toast,
      currPage,
      query,
      fetchWithdraw,
      watch,
      refetchData,
      refWithdrawTable,
      totalRecords,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
